import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import MenuButton from '@components/controls/MenuButton/MenuButton';
import { UserContext } from '../../contexts/UserContext';
import PageTitle from '@components/layout/PageTitle';
import UserProfileMenu from './UserProfileMenu';
import { ThemeSwitcher } from '@components/menus/ThemeSwitcher';
import IconBugs from '../../bugsReporting/bugs.icon';
import { BugsModal } from '../../bugsReporting/bugs.modal';

interface PropsT {
  title: string;
}

const debug = false;

const TopMenu = (props: PropsT) => {
  const { title } = props;
  const { user } = useContext(UserContext);
  // check to see if signed
  debug &&
    console.log('UserProfileMenu | isAppAuthorized:', user?.isAppAuthorized);
  const [isBugsModalOpen, setIsBugsModalOpen] = useState(false);

  const handleBugs = () => {
    setIsBugsModalOpen((previous) => !previous);
    debug && console.log('handleBugs');
  };

  return (
    <>
      <Header>
        <HeaderLeft>
          <PageTitle title={title} />
        </HeaderLeft>
        <HeaderCenter>
          <SubscriptionContainer>
          </SubscriptionContainer>
        </HeaderCenter>
        <HeaderRight>
          <ItemContainer>
            <ThemeSwitcher />
          </ItemContainer>
          {user?.isAppAuthorized ? (
            <ItemContainer>
              <MenuButton
                icon={<IconBugs size={24} />}
                onClick={handleBugs}
                isActive={isBugsModalOpen}
                spacing={'horizontal'}
                text={'Submit a Bug Report or Suggestion'}
                tooltipPlacement={'bottom-end'}
              />
            </ItemContainer>
          ) : (
            <></>
          )}
          <ItemContainer>
            <UserProfileMenu />
          </ItemContainer>
        </HeaderRight>
      </Header>
      <BugsModal
        modalOpen={isBugsModalOpen}
        setModalOpen={setIsBugsModalOpen}
      />
    </>
  );
};

export default TopMenu;

const Header = styled.div`
  display: grid;
  padding: 0;
  max-height: 50px;
  grid-auto-flow: column;
  grid-template-columns: min-content min-content;
  justify-content: space-between;
  height: max-content;
  width: 100%;
  margin-bottom: -1px;
  align-items: center;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
`;

const HeaderLeft = styled.div`
  min-width: 350px;
  display: grid;
  grid-auto-flow: column;
  justify-self: start;
  white-space: nowrap;
`;

const HeaderCenter = styled.div`
  min-width: 200px;
  display: flex;
  grid-auto-flow: column;
  justify-self: center;
  white-space: nowrap;
`;

const HeaderRight = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-self: end;
  padding-right: 20px;
`;

const ItemContainer = styled.div`
  height: 50px;
  padding: 0;
  margin: 0;
  width: max-content;
  align-items: center;
  display: grid;
`;

const SubscriptionContainer = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
