import React from 'react';
import styled from 'styled-components/macro';
import { Logo, Name, Row, Title } from '@components/account/forms/FormStyles';
import { APP_CONFIG } from '../../../App.config';
import HoverInfoText from '../fields/HoverInfoText';

const AppDialogHeader = ({message, tooltip=''}) => {
  return(
    <>
      <Row height={'50px'}>
        <Logo>
          <img src={APP_CONFIG.logo} alt='logo' />
        </Logo>
        <Name>{APP_CONFIG.name}</Name>
      </Row>
      <Row height={'50px'}>
        <Title>{message}
        { tooltip !== '' ?
          <TooltipContainer>
            <HoverInfoText text={tooltip} />
          </TooltipContainer>
          : null}
        </Title>
      </Row>
    </>
  )
}

export default AppDialogHeader;

const TooltipContainer = styled.div`
  margin-left: 16px;
`;