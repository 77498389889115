import React, { useContext } from 'react';
import PageContainer from '../components/pages/PageContainer';
import { PageWideScrollBox } from "@components/pages/page.layout";
import PagePanel from "../components/pages/PagePanel";
import PageTiles from "@components/layout/PageTiles";
import {UserContext} from "../contexts/UserContext";
import {MenuContext} from "../contexts/MenuContext";
import {asedaCards, appCards} from "@pages/config/cards.config";


const debug = true;
// Show the main page of tiles for the site (linked to the logo)
const PgHome = () => {
  const { user } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);

  if (activeMenuId !== 'home') setActiveMenuId('home');

  debug && console.log("PgHome | userInfo:", user);

  return (
    <PageContainer title='Welcome'>
      <PageWideScrollBox>
        <PagePanel title={"GoStar Search and Analysis Tools"} width={"100%"}>
          <PageTiles
              isAuthorized={user.isAppAuthorized}
              cards={appCards}
          />
        </PagePanel>
        <PagePanel title={"AsedaSciences Active Applications"} width={"100%"}>
          <PageTiles
              isAuthorized={user.isAppAuthorized}
              cards={asedaCards}
          />
        </PagePanel>
      </PageWideScrollBox>
    </PageContainer>
  );
};

export default PgHome;

