import styled from 'styled-components/macro';

export const FlexRowSpaced = styled.div<{height?: string, width?: string}>`
  width: ${(p) => p.width };
  height: ${(p) => p.height };
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
FlexRowSpaced.defaultProps = {
  height: 'max-content',
  width: '100%'
}

export const FlexRow = styled.div<{height?: string, width?: string, h_centered?:boolean, v_centered?:boolean}>`
  ${props => props.width && `
    width: ${/^\d+(?:px|%)/.test(props.width) ? props.width : '100%'};
  `}
  height: ${(p) => p.height };
  display: flex;
  flex-direction: row;
  align-items: ${props => props.v_centered ? 'center' : 'flex-start'};
  justify-content: ${props => props.h_centered ? 'center' : 'flex-start'};
`;
FlexRow.defaultProps = {
  h_centered: false,
  v_centered: false,
  height: 'max-content',
  width: 'max-content'
}

export const FlexColumn = styled.div<{width?: string, h_centered?:boolean, v_centered?:boolean}>`
  ${props => props.width && `
    width: ${/^\d+(?:px|%)/.test(props.width) ? props.width : '100%'};
  `}
  display: flex;
  flex-direction: column;
  align-items: ${props => props.h_centered ? 'center' : 'flex-start'};
  justify-content: ${props => props.v_centered ? 'center' : 'flex-start'};
`;
FlexRow.defaultProps = {
  h_centered: false,
  v_centered: false,
}

export const FlexItem = styled.div<{height?: string, width?:string, h_centered?:boolean}>`
  display: flex;
  padding: 3px;
  color: ${(p) => p.theme.palette.textPrimary};
  ${props => props.width && `
    width: ${/^\d+(?:px|%)/.test(props.width) ? props.width : 'auto'};
  `}
  ${props => props.height && `
    width: ${/^\d+(?:px|%)/.test(props.width) ? props.width : 'max-content'};
  `}
  align-content: ${props => props.h_centered ? 'center' : 'flex-start'};
`;
FlexRow.defaultProps = {
  h_centered: false,
  v_centered: false,
}

export const FlexSpacer = styled.div<{width?:number}>`
  display: flex;
  width: ${(p) => p.width}px;
`;
FlexSpacer.defaultProps = {
  width: 50,
}
