import React, { useContext } from "react";
import { StyleContext } from "@theme/AppStyles";

const DashboardIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_684_21253)">
          <rect x="13.75" y="13.75" width="7.5" height="2.5" rx="1.25" stroke={color} strokeWidth="1.5" />
          <rect x="13.75" y="2.75" width="7.5" height="2.5" rx="1.25" stroke={color} strokeWidth="1.5" />
          <rect x="13.75" y="18.75" width="7.5" height="2.5" rx="1.25" stroke={color} strokeWidth="1.5" />
          <rect x="13.75" y="7.75" width="7.5" height="2.5" rx="1.25" stroke={color} strokeWidth="1.5" />
          <path
            d="M5.63397 14.5C6.01887 13.8333 6.98113 13.8333 7.36603 14.5L9.9641 19C10.349 19.6667 9.86788 20.5 9.09808 20.5H3.90192C3.13212 20.5 2.651 19.6667 3.0359 19L5.63397 14.5Z"
            stroke={colorHighlight}
            strokeWidth="1.5"
          />
          <path
            d="M5.63397 3.5C6.01887 2.83333 6.98113 2.83333 7.36603 3.5L9.9641 8C10.349 8.66667 9.86788 9.5 9.09808 9.5H3.90192C3.13212 9.5 2.651 8.66667 3.0359 8L5.63397 3.5Z"
            stroke={colorHighlight}
            strokeWidth="1.5"
          />
        </g>
        <defs>
          <clipPath id="clip0_684_21253">
            <rect width={size - 4} height={size - 4} fill={color} transform="translate(2 2)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default DashboardIcon;
