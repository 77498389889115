import React from "react";
import styled from "styled-components/macro";
import TopMenu from "../menus/TopMenu";

interface propsT {
  title: string;
  children: any;
}

const currentVersion = "0.5.0";

// TODO: Update the calls to retrieve the Info
const PageContainer = (props: propsT) => {
  const { title } = props;
  const version = 'API:' + process.env.REACT_APP_API_HOST
      + '-UI:' + process.env.REACT_APP_HOST_TYPE
      + '-' + currentVersion;

  return (
    <Container>
      <TopMenu title={title} />
      <PageContent>
        {props.children}
      </PageContent>
      <PageFooter>vers:{version}</PageFooter>
    </Container>
  );
};

export default PageContainer;

const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: calc(100vw - 50px);
  top: 0;
  left: 50px;
`;

const PageContent = styled.div`
  position: relative;
  width: calc(100vw - 50px);
  padding: 0;
  margin: 5px;
  flex: auto;
  & ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  & ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  & ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const PageFooter = styled.div`
  position: absolute;
  height: 18px;
  bottom: 0;
  right: 2px;
  font-size: 8px;
  color: ${(p) => p.theme.palette.textQuaternary};
`;
