import React, {useContext} from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import {UserContext} from "../../contexts/UserContext";
import {TextRegular} from "@components/elements/TextStyles";
import {FlexColumn,FlexRow,FlexItem} from "@components/layout/FlexStyles";
import TextButton from "@components/controls/TextButton/TextButton";
import {useNavigate} from "react-router-dom";

const userFields = [
  { 'label': 'Email', 'key': 'authInfo.email', 'type': 'string' },
  { 'label': 'Name - First', 'key': 'regInfo.name.first', 'type': 'string' },
  { 'label': 'Name - Last', 'key': 'regInfo.name.last', 'type': 'string' },
  { 'label': 'Address Street/PO', 'key': 'regInfo.address.address', 'type': 'string' },
  { 'label': 'Town/City', 'key': 'regInfo.address.town', 'type': 'string' },
  { 'label': 'State/Province', 'key': 'regInfo.address.state', 'type': 'string' },
  { 'label': 'Zipcode/PostalCode', 'key': 'regInfo.address.postalCode', 'type': 'string' },
  { 'label': 'Country', 'key': 'regInfo.address.country', 'type': 'string' },
]

const debug = false;
const UserDetail = () => {
  const { user } = useContext(UserContext);
  debug && console.log("UserDetail | user:", user);

  const navigate = useNavigate();

  // handle user who is not registered -- backwards compatibility
  if (Object.keys(user.regInfo).length === 0) {
    return(
        <UserEditContainer>
          <FlexColumn>
            <FlexRow>
              <TextRegular>{"No registration information available verified email: " +
                 user.authEmail}</TextRegular>
            </FlexRow>
            <FlexRow  >
              <FlexItem>
                <TextRegular>Please Register:</TextRegular>
              </FlexItem>
              <FlexItem>
                <TextButton
                    label="Register Now"
                    onClick={() => navigate('/userRegistration')}
                />
              </FlexItem>
            </FlexRow>
          </FlexColumn>
        </UserEditContainer>
    )
  }

  const showValue = (user, item) => {
    if (item.type === 'array') {
      let values = _.get(user, item.key, []);
      return values.join(', ')
    } else {
      return (_.get(user, item.key, ''))
    }
  }

  return(
    <UserEditContainer>
      { userFields.map((item) => {
          return (
              <UserInformation key={item.key}>
                <Label>{item.label}:</Label>
                <Value>{showValue(user, item)}</Value>
              </UserInformation>
          )
        })
      }
    </UserEditContainer>
  )
}
export default UserDetail;

const UserEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  display: flex;
  width: 150px;
  margin-right: 5px;
  justify-content: flex-end;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textSecondary };
`;

const Value = styled.div`
  display: flex;
  font-size: 14px;
`;