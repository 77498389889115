import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '../../../contexts/UserContext';
import { Row, StyledA } from './FormStyles';
import UserAccountPolicies from '../fields/UserAccountPolicies';
import {
  fromDotNotation,
  getPasswordStrength,
  isValidEmail,
  renderAccountInputField,
} from '../utils';
import { userFormFields } from './config';
import useCognito from '@components/account/useCognito';
import Button from '@components/account/fields/Button';
import useUserRegistrationInfo from '@utils/useUserRegistrationInfo';
import ErrorMessage from '@components/account/fields/ErrorMessage';
import { registerTooltipInfo } from './config';
import useUserInfo from '@utils/useUserInfo';
import AppDialogHeader from '@components/account/components/AppDialogHeader';

// note -- handle all messages internally until everything is done so that a new component does not get
// created -- and maintain user information

const isValidField = (field:string, userData) => {
  if (!Object.hasOwn(userData, field) || userData[field] === '') return false;
  if (field === 'email') return isValidEmail(userData[field]);
  if (field === 'organization.name') return (Object.hasOwn(userData, 'organization.type') && userData['organization.type'] !== '');
  if (field === 'password')  return (getPasswordStrength(userData['password']).strength === 5);
  return true;
}

const debug = false;
const SignUp = ({ setMessage }) => {
  const [userDataValidationKeys, setUserDataValidationKeys] = useState<string[]>([]);
  const [userData, setUserData] = useState({ 'address.billing': true });
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const [registeringUser, setRegisteringUser] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [clickedOnce, setClickedOnce] = useState<boolean>(false);
  const [fieldUpdated, setFieldUpdated] = useState<boolean>(false);
  const { setUser } = useContext(UserContext);
  const { login, createAccount } = useCognito();
  const { getUserInfo } = useUserInfo();
  const { createUserRegistrationInfo } = useUserRegistrationInfo();

  // capture the user input to update validation state as needed
  const handleBlur = () => {
    if (clickedOnce) {
      const newIsValid = isFormValid();
      setIsValid(newIsValid);
      setFieldUpdated(true);
    }
  };

  // set appropriate fields in the userData object
  const handleChange = (key:string, value:string) => {
    setUserData((prev) => ({ ...prev, [key]: value }));
  };

  // confirm the form fields are filled and valid before submitting
  const isFormValid = () => {
    let emptyFields = [];
    userDataValidationKeys.forEach((k) => {
      if (!Object.hasOwn(userData, k) || userData[k] === '') emptyFields.push(k);
    });
    if (emptyFields.length > 0) {
      setErrorMessage('Not all fields set');
      return false;
    }
    // check email
    if (!isValidEmail(userData['email'])) {
      setErrorMessage('Not valid email format');
      return false;
    }
    // check password strength
    if (Object.hasOwn(userData, 'password') && getPasswordStrength(userData['password']).strength < 5) {
      setErrorMessage('Password fails requirements.');
      return false;
    }
    setErrorMessage('');
    return true;
  }

  const handleSubmit = () => {
    if (!clickedOnce) setClickedOnce(true);
    if (isFormValid()) {
      setCreatingAccount(true);
      createAccount(userData['email'], userData['password'])
        .then(
          // successfully created the user account -- ignore the result
          () => {
            setCreatingAccount(false);
            // authenticate
            setRegisteringUser(true);
            login(userData['email'], userData['password'])
              .then((result) => {
                // set the user information from the userContext
                const userSession = result;
                const userId = userSession.getIdToken().payload;
                const token = userSession.getAccessToken().getJwtToken();
                setUser((prev) => ({
                  ...prev,
                  isAuthenticated: true,
                  authId: userId['cognito:username'],
                  authEmail: userId.email,
                  authSession: result,
                }));
                // register user and set their information
                const info = fromDotNotation(userData);
                if (Object.hasOwn(info, 'password')) delete info['password']; // make sure password is not sent
                debug && console.log('info for creating user', info);
                createUserRegistrationInfo(token, info)
                  .then((response) => {
                    // @ts-ignore
                    if (response?.data.length) {
                      const data = response?.data[0];
                      // as it is a new user, set to isAppAuthorized and add the zero credits
                      setUser((prev) => ({
                        ...prev,
                        regInfo: data?.user,
                        isRegistered: true,
                        isAppAuthorized: true,
                      }));
                      // store locally in case user hits reload -- or for stripe direct / redirect
                      // Note that on create the data returned included object id and user key
                      console.log('&&&& userId:', userId);
                      const dataKey = 'storedUserRegData_' + userId.sub;
                      debug && console.log(`SignUp | saving UserReg data to "${dataKey}" | UserReg data:`, data?.user);
                      localStorage.setItem(dataKey, JSON.stringify(data?.user));
                      // force creation of use account - in SciScore
                      getUserInfo(token, {login: true}).then((response)=>{console.log(response)});
                      setMessage('Registering User Information'); // complete within component -- push back to parent
                    } else {
                      setRegisteringUser(false);
                      console.error('RegistrationException: ', response?.errors);
                      setErrorMessage(response?.errors[0]);
                    }
                  })
                  .catch((error) => {
                    setRegisteringUser(false);
                    console.error('CreateUserRegistrationAPIError: ', error);
                    setErrorMessage(error);
                  });
              })
              .catch((error) => {
                // authentication error
                setRegisteringUser(false);
                console.error('CognitoAuthenticationException: ' + error);
                setErrorMessage(error);
              });
          }
        )
        .catch((error) => {
          setCreatingAccount(false);
          console.error('CognitoSignupException: ' + error);
          const cleanError = error.replace('UsernameExistsException: ', 'Error: ');
          setErrorMessage(cleanError);
        });
    }
  };
  // update form when onceSubmitted and field changes
  useEffect(() => {
    if (clickedOnce) {
      if (fieldUpdated) setFieldUpdated(false);
    }
  }, [clickedOnce, fieldUpdated, userData]);

  // set validation keys on first creation
  useEffect(() => {
    let userDataKeys = [];
    userFormFields.forEach((group) => {
      group.forEach((field) => {
        if (field.value !== 'password') userDataKeys.push(field.value);
      });
    });
    userDataKeys.push('organization.type');
    setUserDataValidationKeys(userDataKeys);
  }, []);

  return (
    <SignUpForm key={'user-sign-up'}>
      {creatingAccount ? (
        <StatusMessage>Creating User Account</StatusMessage>
      ) : registeringUser ? (
        <StatusMessage>Registering User Information</StatusMessage>
      ) : (
        <UserForm key={'user-sign-up-form'}>
          <AppDialogHeader message={'Create an Account'} tooltip={registerTooltipInfo} />
          <Row height={'18px'}>
            {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
          </Row>
          {userFormFields.map((row, index) => (
            <Row width={'100%'} key={'row_' + index}>
              {row.map((info, row_index) =>
                renderAccountInputField(info, `${index}_${row_index}`,
                userData, handleChange, clickedOnce && !isValidField(info.value, userData),
                handleBlur)
              )}
            </Row>
          ))}
          <Row>
            <UserAccountPolicies />
          </Row>
          <Row>
            <Button
              type={'submit'}
              label={'Create Account'}
              onClick={handleSubmit}
            />
          </Row>
          <Row>
            <StyledA
                key={'return'}
                href={'/user/login'}
                size={'12px'}
                topMargin={'10px'}
            >
              return to sign-in
            </StyledA>
            { fieldUpdated && isValid? <></> : null}
          </Row>
        </UserForm>
      )}
    </SignUpForm>
  );
};

export default SignUp;

const SignUpForm = styled.div`
  width: 550px;
  height: max-content;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusMessage = styled.div`
  display: flex;
  padding: 50px;
  font-size: 24px;
  border: 2px solid ${(p) => p.theme.palette.accentPrimary};
  border-radius: 30px;
`;

const UserForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px;
  min-width: 525px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;
