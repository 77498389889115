import React, { useContext } from "react";
import styled from 'styled-components/macro';
import { StyleContext } from "@theme/AppStyles";

const PageIcon = ({ size = 24, page = 0 }) => {
  const [style] = useContext(StyleContext);
  const color = style.name === "dark" ? "white" : "black";
  return (
    <IconContainer>
      <PageNumber>{page}</PageNumber>
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.28683 0C2.88601 0 1.70839 1.0159 1.47294 2.3577C1.44442 2.52023 1.42969 2.68747 1.42969 2.85714V17.1429C1.42969 18.7134 2.71633 20 4.28683 20H5.7154C5.90484 20 6.08652 19.9247 6.22048 19.7908C6.35443 19.6568 6.42969 19.4752 6.42969 19.2857C6.42969 19.0963 6.35443 18.9146 6.22048 18.7806C6.08652 18.6467 5.90484 18.5714 5.7154 18.5714H4.28683C3.48305 18.5714 2.85826 17.9466 2.85826 17.1429V2.85714C2.85826 2.7703 2.8663 2.68599 2.88058 2.60463C2.99844 1.93295 3.56989 1.42857 4.28683 1.42857H12.144V3.57143C12.144 4.74621 13.112 5.71429 14.2868 5.71429H17.144V17.1429C17.144 17.9466 16.5192 18.5714 15.7154 18.5714H8.57255C8.3831 18.5714 8.20142 18.6467 8.06747 18.7806C7.93351 18.9146 7.85826 19.0963 7.85826 19.2857C7.85826 19.4752 7.93351 19.6568 8.06747 19.7908C8.20142 19.9247 8.3831 20 8.57255 20H15.7154C17.2859 20 18.5725 18.7134 18.5725 17.1429V5.50502C18.5725 4.99246 18.3686 4.50027 18.0061 4.13784L14.4347 0.566406C14.0723 0.203972 13.5801 0 13.0675 0H4.28683ZM13.5725 1.72433L16.1339 4.28571H14.2868C13.8788 4.28571 13.5725 3.9795 13.5725 3.57143V1.72433Z"
          fill={color}
        />
      </svg>
    </IconContainer>
  );
};

export default PageIcon;

export const IconContainer = styled.div`
  position: relative;
`;

export const PageNumber = styled.div`
  position: absolute;
  font-size: 15px;
  z-index: 1;
  top: 5px;
  left: 4px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.accentPrimary };
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;