import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import LogoutIcon from '@mui/icons-material/Login';
import MenuButton from '@components/controls/MenuButton';
import useCognito from '@components/account/useCognito';
import { UserContext } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import AppDialogHeader from '@components/account/components/AppDialogHeader';
import { StyledA } from '@components/account/forms/FormStyles';

// note -- handle all messages internally until everything is done so that a new component does not get
// created -- and maintain user information
// this is called if a user already has an account in cognito but is not registered

const debug = false;
// TODO -- add the registration page here -- decompose Sign-Up so that it uses same component
const Register = () => {
  const { user, setUser } = useContext(UserContext);
  const { logout } = useCognito();
  const navigate = useNavigate();

  debug && console.log('Register | user:', user);
  const handleLogout = () => {
    logout(user?.authEmail);
    navigate('/user/login');
  };

  return (
    <RegisterForm key={'user-register'}>
      <AppDialogHeader message={'Missing User Registration Information'}/>
      <Message>
        You have landed at this page as it appears that you have an authenticated account, but no user registration details. This is an
        unusual situation as your registration details should have been saved when you created your account.
      </Message>
      <Message>
        Please email us using the link below and we will respond on how to correct this problem.
      </Message>
      <StyledA href={'mailto:support@asedasciences.com'}>Contact AsedaSciences Support</StyledA>
      <Message>
        <MenuButton
          key={'register_logout'}
          text={'Sign Out of Account'}
          icon={<LogoutIcon />}
          label={'Sign-Out'}
          onClick={handleLogout}
        />
      </Message>
    </RegisterForm>
  );
};

export default Register;

const RegisterForm = styled.div`
  width: 550px;
  height: max-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${(p) => p.theme.palette.accentPrimary};
  border-radius: 30px;
`;

const Message = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.textSecondary };
  margin: 5px;
  display: flex;
`;
