import React, { useContext } from "react";
import { StyleContext } from "@theme/AppStyles";

const SearchMolIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M18.6,20.6l-6.5-6.5c-0.3-0.3-0.3-0.9,0-1.3l0.3-0.3c0.3-0.3,0.9-0.3,1.3,0l6.5,6.5c0.3,0.3,0.3,0.9,0,1.3l-0.3,0.3
C19.6,21,19,21,18.6,20.6z"/>
      <path fill={color} d="M9.1,14.1c-3,0-5.5-2.5-5.5-5.5c0-3,2.5-5.5,5.5-5.5c3,0,5.5,2.5,5.5,5.5C14.6,11.6,12.1,14.1,9.1,14.1z M9.1,4.1
c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S11.6,4.1,9.1,4.1z"/>
      <path fill={colorHighlight} d="M12.3,9.5V7.7c0.2-0.2,0.4-0.4,0.4-0.7c0-0.5-0.4-0.9-0.9-0.9c-0.1,0-0.3,0-0.4,0.1L9.9,5.4
c0-0.4-0.4-0.8-0.8-0.8S8.3,5,8.2,5.4L6.7,6.3c-0.1,0-0.2-0.1-0.3-0.1c-0.5,0-0.9,0.4-0.9,0.9c0,0.3,0.1,0.5,0.4,0.7v1.7
c-0.2,0.2-0.3,0.4-0.4,0.6c0,0.2,0.1,0.4,0.2,0.6C5.9,10.9,6.1,11,6.3,11c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1l1.5,0.9
c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8l1.5-0.9c0.1,0,0.2,0.1,0.3,0.1c0.5,0,0.9-0.4,0.9-0.9C12.6,9.9,12.5,9.6,12.3,9.5z
 M10.9,10.1l-1.5,0.9c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1l-1.5-0.9c0-0.2-0.1-0.5-0.3-0.6V7.7c0.2-0.1,0.3-0.4,0.3-0.6
l1.5-0.9c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1l1.5,0.9c0,0.2,0.2,0.4,0.3,0.6v1.8C11.1,9.6,11,9.8,10.9,10.1z"/>
    </svg>
  );
};

export default SearchMolIcon;
