import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import PgAuthentication from '@pages/public/PgAuthentication';

const AppRoutesPublic = () => {
  return (
    <Content>
      <Routes>
        <Route path='/' element={<Navigate replace to='/user/login' />} />
        <Route path='/*' element={<Navigate replace to='/user/login' />} />
        <Route path='/user/:action'
               element={<PgAuthentication />}
        />
      </Routes>
    </Content>
  );
};

export default AppRoutesPublic;

const Content = styled.div`
  position: absolute;
  margin: 0;
  top: 0;
  left: 50px;
  width: calc(100vw - 50px);
  height: 100vh;
  background: ${(p) => p.theme.palette.backgroundPrimary};
`;
