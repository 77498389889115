import styled from "styled-components/macro";

export const PageWideScrollBox = styled.div<{topMargin?: number}>`
  height: calc(100vh - 50px - ${(p) => p.topMargin}px);
  width: calc(100vw - 57px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
`;
PageWideScrollBox.defaultProps = {
  topMargin: 0,
}