import React from "react";

import HomeIcon from "@components/icons/IconHome";
import DashboardIcon from "@components/icons/dashboard.icon";
import InfoIcon from "@components/icons/info.icon";
import PageIcon from "@components/icons/page.icon";
import SearchMolIcon from "@components/icons/searchMol.icon";

export const menuItems = [
  {
    id: "home",
    name: "Home",
    label: "HM",
    linkTo: "/home",
    icon: <HomeIcon />,
    authorizedOnly: false,
  },
  {
    id: "dashboard",
    name: "DashBoard",
    label: "DB",
    linkTo: "/dashboard",
    icon: <DashboardIcon />,
    authorizedOnly: false,
  },
  {
    id: "search",
    name: "Search Database",
    label: "SRCH",
    linkTo: "/search",
    icon: <SearchMolIcon />,
    authorizedOnly: true,
  },
  {
    id: "page2",
    name: "Page 2",
    label: "PG1",
    linkTo: "/page/2",
    icon: <PageIcon page={2}/>,
    authorizedOnly: true,
  },
  {
    id: "info",
    name: "Info",
    label: "IF",
    linkTo: "/info",
    icon: <InfoIcon/>,
    authorizedOnly: true,
  },
];