import styled from 'styled-components/macro';
import ProgressBar from './ProgressBar';
import { getPasswordStrength } from '../../utils';
import { passwordStrengthLabels, passwordStrengthColors } from './config';
import HoverInfoText from '../HoverInfoText';
import { passwordRequirements } from '@components/account/forms/config';

interface propsT {
  password: string;
}

const PasswordStrengthBar = (props: propsT) => {
  const { password } = props;
  const strength = getPasswordStrength(password).strength;

  return (
    <PasswordStrengthBarContainer>
      <PasswordLabel>{passwordStrengthLabels[strength]}</PasswordLabel>
      <PasswordBar>
        <ProgressBar
          bars={5}
          level={strength}
          color={passwordStrengthColors[strength]}
        />
      </PasswordBar>
      <PasswordInfoHolder>
        <HoverInfoText text={passwordRequirements} />
      </PasswordInfoHolder>
    </PasswordStrengthBarContainer>
  );
};

export default PasswordStrengthBar;

const PasswordStrengthBarContainer = styled.div`
  padding-top: 3px;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
`;

const PasswordLabel = styled.div`
  display: flex;
  font-size: 11px;
  width: 25%;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const PasswordBar = styled.div`
  display: flex;
  width: 65%;
  align-items: center;
  justify-content: center;
`;

const PasswordInfoHolder = styled.div`
  position: absolute;
  right: 0;
`;
