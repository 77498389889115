// temporary page
import React from 'react';
import styled from 'styled-components/macro';
import { useParams } from "react-router-dom";
import PageContainer from '@components/pages/PageContainer';
import PagePanel from "@components/pages/PagePanel";
import {FlexRow} from "@components/layout/FlexStyles";

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgPage = () => {
  const { pageNumber } = useParams();
  debug && console.log("PgHome | pageNumber: ", pageNumber);
  const title = 'PlaceHolder Page' + pageNumber;

  return (
    <PageContainer title={title}>
        <PagePanel title={"Page"} width={"98%"}>
          <Container>
            <FlexRow v_centered h_centered>{pageNumber} - Dan Update</FlexRow>
          </Container>
        </PagePanel>
    </PageContainer>
  );
};

export default PgPage;

const Container = styled.div`
  font-size: 123px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  align-content: center;
  justify-content: center;
`;
