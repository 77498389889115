import React, { useContext, ReactNode, useEffect, useState, useRef } from 'react';
import {UserContext, UserEmpty} from '../../contexts/UserContext';
import * as AWSCognitoIdentity from 'amazon-cognito-identity-js';
import useCognito from '@components/account/useCognito';
import { useNavigate } from 'react-router-dom';
import LogoutAlertDialog from '@components/account/forms/LogoutAlertDialog';

/*
  Primary component to handle the user authentication and inactivity states
  Parameters:
    TIME_OUT_SINCE_AUTHENTICATION: the allowed time since user authenticated -- will not be used unless user inactive
    CHECK_AUTHENTICATION_PERIOD: time interval for the app to recheck inactivity and time since authenticated
    INACTIVITY_TIME_OUT: period of user inactivity to be considered inactive
 */
 // milliseconds = 10 minutes
import config from './config/cognito.json';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
const TIME_OUT_SINCE_AUTHENTICATION:number = 60 * 60; // seconds == 1 hour
const CHECK_AUTHENTICATION_PERIOD:number = 60 * 1000; // milliseconds = 1 minutes
const INACTIVITY_TIME_OUT:number = 10 * 60 * 1000

interface AuthenticatorProps {
  children: ReactNode;
}

const debug: boolean = true;
const AuthWrapper: React.FC<AuthenticatorProps> = ({ children }) => {
  const { logout, getSecondsSinceAuthentication } = useCognito();
  const navigate = useNavigate();
  const [isUserActive, setIsUserActive] = useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { user, setUser } = useContext(UserContext);
  const { isAuthenticated } = user;
  debug && console.log('AuthWrapper | alertOpen', alertOpen, 'user:', user);

  let timer: string | number | NodeJS.Timeout;

  // need to use references for functions so do not have stale states
  const isAuthenticatedRef = useRef(isAuthenticated);
  const userSessionRef = useRef(user?.authSession);
  const isUserActiveRef = useRef(isUserActive);
  isAuthenticatedRef.current = user.isAuthenticated;
  isUserActiveRef.current = isUserActive;
  userSessionRef.current = user.authSession;

  // action to take when user inactive or too long since authentication
  const logoutAction = (showAlert:boolean) => {
    // alert to restart
    setIsLoggingOut(true);
    debug && console.log('logout Action triggered | user', user);
    if (showAlert) setAlertOpen(true);
    logout(user?.authEmail).then(()=>{
      setUser(UserEmpty);
      setIsLoggingOut(false);
      navigate('/user/login');
    });
  };

  const addActivityListeners = () => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }

  const removeActivityListeners = () => {
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, resetTimer);
    });
  }

  const checkActiveAuthentication = () => {
    if (isAuthenticatedRef.current) {
      const secondsSinceAuth = getSecondsSinceAuthentication(userSessionRef.current);
      if (!isUserActiveRef.current || secondsSinceAuth > TIME_OUT_SINCE_AUTHENTICATION) {
        removeActivityListeners();
        logoutAction(false);
      }
    }
  };

  // Set the event timer for checking user activity and authentication;
  useEffect(() => {
    const timerId = setInterval(
      checkActiveAuthentication, CHECK_AUTHENTICATION_PERIOD
    );
    return () => clearInterval(timerId);
  }, []);

  // AutoLogout : this function sets the timer that logs out the user after 10 minutes of inactivity
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      setIsUserActive(false);
      isUserActiveRef.current = false;
    }, INACTIVITY_TIME_OUT);
  };

// User activity event -- resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // For inactivity -- when authentication state changes adds event listeners to the window
  // Each time any event is triggered, reset timer
  // If not events over specified timeout (INACTIVITY_TIME_OUT) the app automatically logs out.
  useEffect(() => {
    if (isAuthenticated) {
      debug && console.log('Inactivity Time Out Check -- activating');
      setIsUserActive(true);
      addActivityListeners();
    } else {
      debug && console.log('Inactivity Time Out-- de-activating');
      removeActivityListeners();
    }
  }, [isAuthenticated]);


  // If user not authenticated (first time) -- check to see if user has active session using cognito
  // -- needed for the redirects back from Stripe (or other applications)
  useEffect(() => {
    if (!isAuthenticated) {
      const poolData = {
        UserPoolId: config.userPoolId,
        ClientId: config.clientId,
      };
      const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
      const cognitoUser = userPool.getCurrentUser();
      debug && console.log('cognitoUser:', cognitoUser);

      if (cognitoUser !== null) {
        // only reload if not longer than authentication timeout
        cognitoUser.getSession(function (err, session) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }

          debug && console.log('Reloading user session to confirm authentication', session);
          if (getSecondsSinceAuthentication(session) > TIME_OUT_SINCE_AUTHENTICATION) {
            debug && console.log('Expiring user authentication state --- too long since login ...');
            logoutAction(false);
          }
          setIsUserActive(true);
          const userId = session.getIdToken().payload;
          debug && console.log('AuthWrapper | Session: userId', userId);
          const dataKey = 'storedUserRegData_' + userId?.sub;
          debug && console.log(`AuthWrapper | locating user data using key "${dataKey}"`);
          let userReg = {};
          if (localStorage.getItem(dataKey) !== null) {
            const storedUserData = localStorage.getItem(dataKey);
            userReg = JSON.parse(storedUserData);
            debug && console.log(`AuthWrapper | user data read from local storage key "${dataKey}"`, userReg);
          } else {
            // check to see if registration is available
            console.error('User authenticated through session, but user registration data is not stored');
            navigate('/user/register');
          }
          setUser((prev) => ({
            ...prev,
            isLoading: false, isAuthenticated: true,
            authId: userId['cognito:username'], authEmail: userId.email, authSession: session,
            isRegistered: (Object.keys(userReg).length > 0), regInfo: userReg
          }));
        });
      } else {
        setUser((prev) => ({
          ...prev,
          isLoading: false
        }));
      }
    }
  }, [user.isLoading]);

  return <>
    {children}
    <LogoutAlertDialog
      alertOpen={alertOpen}
      closeAlert={()=>setAlertOpen(false)}
    />
  </>;
};

export default AuthWrapper;
