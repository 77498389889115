import React from "react";

import SciScoreLogo from '@components/icons/sciscore.logo.icon';
import Icon3RnDLogo from "@components/icons/3RnD.icon";
import SearchMolIcon from "@components/icons/searchMol.icon";
import DashboardIcon from "@components/icons/dashboard.icon";

export const asedaCards = [
  {
    title: "Aseda Sciences Website",
    authorizedOnly: false,
    newTab: true,
    icon: <img src={"/img/AsedaSciences_logo.svg"} alt={"AsedaSciences"} width={64} />,
    link: "https://asedasciences.com",
    description: "Look at the main AsedaSciences website for more information",
  },
  {
    title: "3RnD Application",
    authorizedOnly: false,
    newTab: true,
    icon: <Icon3RnDLogo size={64} />,
    link: "https://app-prd.thewarriors3.com",
    description: "High-performance data analysis and visualization",
  },
  {
    id: 'AS_SciScore',
    title: "SciScore",
    authorizedOnly: true,
    newTab: true,
    icon: <SciScoreLogo size={64} />,
    link: "https://sciscore.asedasciences.com",
    description: "Score methods section of a manuscript before submission",
  },
];

export const appCards = [
  {
    title: "Database Overview",
    authorizedOnly: true,
    newTab: false,
    icon: <DashboardIcon size={64} />,
    link: "/dashboard",
    description: "coming soon",
  },
  {
    title: "Search by Compound",
    authorizedOnly: true,
    newTab: false,
    icon: <SearchMolIcon size={64} />,
    link: "/search",
    description: "coming soon",
  }
];


