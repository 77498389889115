import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Dialog } from '@mui/material';
import TextButton from '@components/controls/TextButton/TextButton';
import AppDialogHeader from '@components/account/components/AppDialogHeader';

type Props = {
  alertOpen: boolean;
  closeAlert: () => void;
  width?: number;
  height?: number;
};

const LogoutAlertDialog = (props: Props) => {
  const {
    alertOpen,
    closeAlert,
    width = 400,
    height = 200,
  } = props;
  const message = `You have been timed out due to inactivity at ${new Date().toLocaleString()}`;

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    if (alertOpen) {
      setTimeout(() => {
        // props.closeAlert();
      }, 60000); // 1 minute for user to response -- otherwise close
    }
  }, [alertOpen]);

  return (
    <StyledDialog
      open={alertOpen}
    >
      <Content>
        <AppDialogHeader message={'Session Timeout'} />
        <Message height={height} width={width}>
          <TextItem color={'primary'}>Your session has timed out</TextItem>
          <TextItem>due to session limit (60min) or inactivity (10min)</TextItem>
          <TextItem>{`at ${new Date().toLocaleString()}`}</TextItem>
          <TextItem color={'accent'}>You will need to re-login to continue.</TextItem>
        </Message>
        <CancelContainer>
          <TextButton label={'Close Alert'} onClick={closeAlert} />
        </CancelContainer>
      </Content>
    </StyledDialog>
  );
};

export default LogoutAlertDialog;

const StyledDialog = styled(Dialog)`
  border-radius: 15px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 450px;
  border-radius: 15px;
  align-items: center;
  align-content: space-between;
`;

const Message = styled.div<{ height: number; width: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-bottom: 15px;
  width: calc(${(p) => p.width} - 100) px;
  height: calc(${(p) => p.height} - 150) px;
  border-radius: 10px;
  border: 1px solid ${(p) => p.theme.palette.accentPrimary};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  color: ${(p) => p.theme.palette.textSecondary};
`;

const CancelContainer = styled.div``;

const TextItem = styled.div<{color?: string}>`
  display: flex;
  color: ${(p) => (p.color === 'primary') ?
    p.theme.palette.textPrimary : (p.color === 'accent') ? p.theme.palette.accentPrimary :
    p.theme.palette.textSecondary };
`
TextItem.defaultProps = {
  color: 'secondary'
};