import React from 'react';
import styled from 'styled-components/macro';
import { Tooltip, ButtonBase } from '@mui/material';

const MenuButton = (props) => {
  const { text, icon, onClick, isActive = false, margin="4px 1px", tooltipPlacement = 'right' } = props;
  return (
    <MenuButtonContainer margin={margin}>
      <Tooltip
        title={text}
        placement={tooltipPlacement}
        arrow
      >
        <IconButton
          onClick={onClick}
          className={isActive ? 'active' : ''}
        >
          { icon }
        </IconButton>
      </Tooltip>
    </MenuButtonContainer>
  );
}
export default React.memo(MenuButton);

const MenuButtonContainer = styled.div<{margin: string}>`
  margin: ${(p) => p.margin};
  border-radius: 4px;
  padding: 2px;
  :hover {
    background-color: ${(p) => p.theme.palette.backgroundQuaternary };
  }
`;

const IconButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  
  border-radius: 10px;
  filter: grayscale(1.0) brightness(${(p) => p.theme.menu.inActive.brightness}) 
        contrast(${(p) => p.theme.menu.inActive.contrast});
  :hover {
    filter: grayscale(0.0) brightness(${(p) => p.theme.menu.active.brightness}) 
        contrast(${(p) => p.theme.menu.active.contrast});
  }
  &.active {
    filter: grayscale(0.0) brightness(${(p) => p.theme.menu.active.brightness}) 
        contrast(${(p) => p.theme.menu.active.contrast});
    //background-color: ${(p) => p.theme.palette.backgroundPrimary};
  };
`;
