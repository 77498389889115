import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { APP_CONFIG } from '../../../App.config';
import {Row, Logo, Title, Name, StyledA, LoginButton, FormLabel, BasicTextInput} from './FormStyles';
import ErrorMessage from "@components/account/fields/ErrorMessage";
import useCognito from "@components/account/useCognito";
import Button from '../fields/Button';
import {PasswordStrengthT, getPasswordStrength, isValidEmail} from '../utils';
import PasswordStrengthBar from "@components/account/fields/password/PasswordStrengthBar";
import {VisibilityOffRounded, VisibilityRounded} from "@mui/icons-material";
import PasswordReason from "@components/account/fields/password/PasswordReason";

/**
 * Component to handle the login functionality for AsedaSciences Apps through
 * AWS Cognito -- sets user context upon success and/or other states
 * @constructor
 */
const ForgotPassword = () => {
    const [email, setEmail] = useState<string>('');
    const [codeSent, setCodeSent] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [passwordStrength, setPasswordStrength] = useState<PasswordStrengthT>(
        { strength: 0, reason: ''});
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { resetPassword, confirmPassword } = useCognito();

    // function to handle the submit action if valid form and not in loggingIn state
    const handleSendCode = () => {
      if (!isValidEmail(email)) {
        setErrorMessage('Not valid email - entered incorrectly');
      } else {
        setCodeSent(true);
        setErrorMessage('');
        resetPassword(email).then(()=>{
          // safely ignore result
        }).catch((error)=>{
          console.error('SendCodeException: ', error);
        });
      }
    }

    // function to handle the submit action if valid form and not in loggingIn state
    const handleSetPassword = () => {
      if (getPasswordStrength(newPassword).strength < 5) {
        setErrorMessage('Password does not meet requirements');
      } else {
        confirmPassword(email, verificationCode, newPassword).then(() => {
          // safely ignore result
          setSuccess(true);
        }).catch((error)=>{
          console.error(error)
          setErrorMessage(error.toString());
        });
      }
    }

    // when password changes update the strength
    useEffect(() => {
      setPasswordStrength(getPasswordStrength(newPassword));
    }, [newPassword]);

    // code for entering email to send verification code to user
    function getEmailCodeForm() {
      return(
        <>
          <Row width='400px' align={'flex-start'}><FormLabel>Email Address</FormLabel></Row>
          <Row width={'400px'} height={'36px'}>
            <BasicTextInput
                key='username'
                id='username'
                type='text'
                name='username'
                placeholder='Enter account email to send verification code ...'
                autoComplete='username'
                error={errorMessage !== ''}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
          </Row>
          <LoginButton>
            <Button
                type={'submit'}
                label={'Send Code to Email'}
                disabled={!isValidEmail(email)}
                onClick={handleSendCode}
            />
          </LoginButton>
          <Row height={'16px'}>
            { errorMessage ? <ErrorMessage message={errorMessage} /> : null }
          </Row>
        </>
      )
    }

    // form to set new password after user receives code
    function getPasswordForm() {
      return(
        <>
          <Row><StatusMessage>Code sent to email</StatusMessage></Row>
          <Row width='200px' align={'flex-start'}><FormLabel>Verification Code:</FormLabel></Row>
          <Row width={'200px'} height={'36px'}>
            <BasicTextInput
                key='code'
                id='code'
                type='text'
                name='code'
                placeholder='Enter code from email ...'
                error={errorMessage !== ''}
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
            />
          </Row>
          <Row width={'350px'}>
            <FormLabel>New Password:</FormLabel>
            <Bar><PasswordStrengthBar password={newPassword} /></Bar>
          </Row>
          <Row width={'350px'}>
            <BasicTextInput
                key={'new_password'}
                type={passwordVisibility ? "text" : "password"}
                placeholder={'Enter new password ...'}
                onChange={(e)=>setNewPassword(e.target.value)}
                disabled = {false}
            />
            <Visibility onClick={()=>setPasswordVisibility(!passwordVisibility)}>
              { passwordVisibility ? <VisibilityRounded /> : <VisibilityOffRounded /> }
            </Visibility>
          </Row>
          <PasswordReason password={newPassword}/>
          <LoginButton>
            <Button
                type={'submit'}
                label={'Set New Password'}
                disabled={passwordStrength?.strength < 5}
                onClick={handleSetPassword}
            />
          </LoginButton>
          <Row height={'32px'}>
            { errorMessage ? <ErrorMessage message={errorMessage} /> : null }
          </Row>
        </>
      )
    }

    // use on final success
    function getSuccess() {
      return(
          <Row><StatusMessage>Successfully reset password.</StatusMessage></Row>
      )
    }

    // component render
    return(
      <ForgotPasswordContainer key={'sign-in-form'}>
        <Row height={'50px'}>
          <Logo><img src={APP_CONFIG.logo} alt='logo'/></Logo>
          <Name>{APP_CONFIG.name}</Name>
        </Row>
        <Row height={'50px'}>
          <Title>Reset Password</Title>
        </Row>
        { success ? getSuccess() : codeSent ? getPasswordForm() : getEmailCodeForm() }
        <Row>
          <StyledA key={'return'} href={'/user/login'} size={'12px'}>return to sign-in</StyledA>
        </Row>
      </ForgotPasswordContainer>
    )
}

export default ForgotPassword;

const ForgotPasswordContainer = styled.div`
  width: 450px;
  padding: 15px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;

const StatusMessage = styled(FormLabel)`
  color: ${(p) => p.theme.palette.accentPrimary};
  font-size: 16px;
  font-style: italic;
`;

const Visibility = styled.div`
  position: relative;
  width: 0;
  left: -35px;
  color: ${(p) => p.theme.palette.accentSecondary };
  padding-left: 5px;
  &:hover {
    color: ${(p) => p.theme.palette.accentPrimary };
    cursor: pointer;
  }
`;

const Bar = styled.div`
  display: flex;
  margin-left: 10px;
  width: calc(100% - 10px);
`;


