import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import { TextTitle } from '@components/elements/TextStyles';
import useBugReports from './bugs.api';
import EditIcon from '@components/icons/edit.icon';
import MenuButton from '@components/controls/MenuButton';
import TrashIcon from '@components/icons/trash.icon';
import { APP_CONFIG } from "../App.config";

const BugsList = ({
  newBugReported,
  onActionBug,
  inEditBug,
  deletedBugId,
  setDeletedBugId,
}) => {
  const debug = false;
  const { getBugReports } = useBugReports();
  const [loaded, setLoaded] = useState(false);
  const [bugReports, setBugReports] = useState([]);

  useEffect(() => {
    // refresh bug list if newBugReported changes
    setLoaded(false);
  }, [newBugReported]);

  if (!loaded) {
    const params = { system: APP_CONFIG.name };
    getBugReports(params).then((response) => {
      setLoaded(true);
      setBugReports(response?.data || []);
    });
  }
  debug && console.log('bugReports:', bugReports);

  const getStatus = (report) => {
    let status = 'submitted';
    if (report?.acknowledged) {
      status = 'in queue';
    }
    if (report?.assigned) {
      status = 'assigned';
    }
    return status;
  };

  const getDate = (report) => {
    const utcDate = _.get(report, 'submit.time_submitted', 'NA');
    if (utcDate === 'NA') {
      return utcDate;
    }
    const localDate = new Date(utcDate);
    return localDate.toDateString();
  };

  const mapRow = (report) => {
    return {
      id: report._id,
      name: report.title,
      time_submitted: getDate(report),
      status: getStatus(report),
    };
  };

  useEffect(() => {
    if (deletedBugId) {
      setBugReports((previous) =>
        previous.filter((bug) => bug.uuid !== deletedBugId)
      );
      setDeletedBugId();
    }
  }, [deletedBugId]);

  const actionButtons = (params) => {
    return (
      <>
        <MenuButton
          onClick={() =>
            onActionBug(
              bugReports.find((report) => report._id === params.id),
              'edit'
            )
          }
          icon={<EditIcon />}
          tooltipPlacement={'top'}
          isActive={inEditBug?._id === params.id}
          text={'Edit bug report'}
        />
        <MenuButton
          onClick={() =>
            onActionBug(
              bugReports.find((report) => report._id === params.id),
              'delete'
            )
          }
          icon={<TrashIcon />}
          tooltipPlacement={'top'}
          text={'Delete bug report'}
        />
      </>
    );
  };

  if (loaded === true && bugReports.length === 0) {
    debug && console.log('Not loaded or empty');
    return (
        <Content>
          <FlexItem width={'100%'}><Heading color={'accent'}>Your Currently Open Bugs</Heading></FlexItem>
          <FlexRow width={'100%'} height={'100%'} h_centered>
            <FlexItem><Heading color={'primary'}>You currently do not have any open reported bugs</Heading></FlexItem>
          </FlexRow>
        </Content>

    );
  } else {
    debug && console.log('loaded and Not empty');

    const columns: GridColDef[] = [
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        sortable: false,
        renderCell: (params) => actionButtons(params),
      },
      { field: 'name', headerName: 'Name', width: 200, sortable: false },
      {
        field: 'time_submitted',
        headerName: 'Date Submitted',
        width: 250,
        sortable: false,
      },
      { field: 'status', headerName: 'Status', width: 100, sortable: false },
    ];
    const rows = bugReports.map((report) => mapRow(report));

    return (
      <Content>
        <FlexItem width={'100%'}><Heading color={'accent'}>Your Currently Open Bugs</Heading></FlexItem>
          <StyledDataGrid
            columns={columns}
            rows={rows}
            disableColumnMenu
            hideFooter
          />
      </Content>
    );
  }
};

export default BugsList;

const Content = styled.div`
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
`;

const StyledDataGrid = styled(DataGrid)`
  min-height: 225px;
  min-width: 680px;
  & ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
  }
  & ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  & ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
  & ::-webkit-scrollbar-corner {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
`;

const Heading = styled(TextTitle)`
  font-size: 18px;
`;

export const ScrollBox = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track { 
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
  ::-webkit-scrollbar-corner {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
`;
