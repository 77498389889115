import React, { useContext } from 'react';
import styled from 'styled-components/macro';
// CONTEXT and APIS
import { UserContext } from '../contexts/UserContext';
// COMPONENTS
import PageContainer from '../components/pages/PageContainer';
import PagePanel from "../components/pages/PagePanel";
import UserDetail from '@components/account/UserDetail';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import MenuButton from "@components/elements/MenuButton";
import EditIcon from "@components/icons/edit.icon";
import {useNavigate} from "react-router-dom";

const debug = false;
const PgUserAccount = () => {
  const { user } = useContext(UserContext);
  debug && console.log('PgUserAccount: {userInfo}', user);

  const navigate = useNavigate();

  return (
    <PageContainer title='User Management'>
      <UserAccountContainer>
          <PagePanel title={'My Registration Details'}>
            <FlexRow>
              <FlexItem>
                <MenuButton
                    onClick={()=>navigate('/userRegistration')}
                    tooltipPlacement={'bottom'}
                    icon={<EditIcon />}
                    text={'Edit Registration'}
                />
              </FlexItem>
              <FlexItem>
                <UserDetail />
              </FlexItem>
            </FlexRow>
          </PagePanel>
          <PagePanel title={'My Roles'}>
           Add Roles - request information here
          </PagePanel>
      </UserAccountContainer>
    </PageContainer>
  );
};

export default PgUserAccount;

const UserAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: calc(100vw - 65px);
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
`;
