import React, { useContext, useEffect, useState } from 'react';
import PageContainer from '@components/pages/PageContainer';
import PagePanel from "@components/pages/PagePanel";
import {UserContext} from "../contexts/UserContext";
import { MenuContext } from '../contexts/MenuContext';
import { FlexRow, FlexItem} from '@components/layout/FlexStyles';
import { BasicTextInput } from '@components/account/forms/FormStyles';
import TextButton from '@components/controls/TextButton';
import useCognito from '@components/account/useCognito';

const Options = [
  { value: 'demographic', label: 'Demographic'},
  { value: 'drug', label: 'Drug'}
]

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgSearch = () => {
  const [infoLoaded, setInfoLoaded] = useState<boolean>(false);
  const [selected, setSelected] = useState('');
  const [limit, setLimit] = useState<number>(10);
  const { getToken } = useCognito();

  const { user } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  debug && console.log("PgSearch | userInfo:", user);

  if (activeMenuId !== 'search') setActiveMenuId('search');

  const handleLimitChange = (e) => {
    console.log('handleLimitChange', e.target.value);
    setLimit(e.target.value)
  }

  const handleSubmit = () => {
    console.log('handleSubmit: ', selected, limit);
    // do search
  }

  return (
    <PageContainer title='Search Available Databases'>
      <PagePanel title={"Search Databases"}>
        <FlexRow v_centered>
          <FlexItem width={'200px'}>
            Add Search Form
          </FlexItem>
        </FlexRow>
      </PagePanel>
    </PageContainer>
  );
};

export default PgSearch;

