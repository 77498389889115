import React from "react";
import styled from 'styled-components/macro';
import PageContainer from "@components/pages/PageContainer";
import { FlexColumn, FlexItem } from "@components/layout/FlexStyles";
import TextButton from '@components/controls/TextButton';
import { TextRegular } from '@components/elements/TextStyles';
import DetailsIcon from '@components/icons/details.icon';

const myArray = [];

for (let i = -1; i <= 11; i++) {
  myArray.push(i);
}

const PgDevTest = () => {
  return (
    <PageContainer title={"Developer Component Test Page"} >
      <HeaderRow><TextRegular color={'primary'}>Button Styles</TextRegular></HeaderRow>
      <Row>
        <FlexItem><TextButton label='Normal' /></FlexItem>
        <FlexItem><TextButton label='isDisabled' isDisabled={true}/></FlexItem>
        <FlexItem><TextButton label='isWarning' isWarning={true}/></FlexItem>
        <FlexItem><TextButton label='inProcess' inProcess={true}/></FlexItem>
      </Row>
      <HeaderRow><TextRegular color={'primary'}>Key Icons</TextRegular></HeaderRow>
      <Row>
        <FlexItem><DetailsIcon /></FlexItem>
      </Row>
    </PageContainer>
  );
};

export default PgDevTest;

const Row = styled.div`
  display: flex;
  padding: 5px;
  width: calc(100% - 50px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const HeaderRow = styled(Row)`
  margin-top: 25px;
`;