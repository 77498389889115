import { authCoreClient, authAppClient } from "./base";

export const User = {
  get: (token, config) => authAppClient(token).get("/user/get", config),
  check: (token, config) => authAppClient(token).get("/user/check", config),
  create: (token, data) => authAppClient(token).post("/user/create", data),
  update: (token, data) => authAppClient(token).post("/user/update", data)
};

export const Orders = {
  all: (token, config) => authAppClient(token).get("/order/list", config),
  get: (token, orderId, config) => authAppClient(token).get(`/order/get/${orderId}`, config),
  list: (token, config) => authAppClient(token).get("/order/list", config),
  credits: (token) => authAppClient(token).get("/order/credits")
};

// AsedaSciences Core Services
export const ApiCoreInfo = {
  get: (token) => authCoreClient(token).get("/info/"),
};

// AsedaSciences Core Services
export const UserRegistration = {
  get: (token, config) => authCoreClient(token).get('/user/', config),
  create: (token, data) => authCoreClient(token).post('/user/create', data),
  update: (token, data) => authCoreClient(token).post('/user/update', data),
  delete: (token, data) => authCoreClient(token).post('/user/delete', data)
};

// AsedaSciences Core Services
export const BugReports = {
  get: (token, config) => authCoreClient(token).get("/bugs/", config),
  submit: (token, data) => authCoreClient(token).post("/bugs/submit", data),
  update: (token, data) => authCoreClient(token).put("/bugs/update", data),
  delete: (token, id) => authCoreClient(token).post('/bugs/delete', id)
};
